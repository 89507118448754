import { createRouter, createWebHistory } from "vue-router";
import { isMobileFlag } from "@/config";

console.log(localStorage.getItem("productList"));

let productList = localStorage.getItem("productList");
let productCategoryList = localStorage.getItem("productCategoryList");

const routes_pc = [
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/AnyView.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("@/views/pc/IndexView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pc/LoginView.vue"),
    meta: { footerHidde: false, headHidde: true },
  },
  {
    path: "/index.php",
    name: "indexphp",
    redirect: (to) => {
      console.log("走到路由匹配这里");
      if (productList == null || productList.length == 0) {
        productList = localStorage.getItem("productList");
        productCategoryList = localStorage.getItem("productCategoryList");
        console.log(localStorage.getItem("productList"), "获取缓存");
        console.log(localStorage.getItem("productCategoryList"), "获取缓存");
      }
      if (to.fullPath == "/index.php") {
        return {
          name: "indexforphp",
        };
      } else if ((to.query.c == "category" && to.query.id) == "1") {
        // 产品中心页面
        return {
          name: "product",
        };
      } else if (to.query.c == "category" && to.query.id == "2") {
        // 技术服务页面
        return {
          name: "service",
        };
      } else if (to.query.c == "show" && to.query.id == "16") {
        return {
          name: "viewA",
        };
      } else if (to.query.c == "show" && to.query.id == "19") {
        return {
          name: "viewB",
        };
      } else if (to.query.c == "show" && to.query.id == "18") {
        return {
          name: "viewC",
        };
      } else if (to.query.c == "show" && to.query.id == "20") {
        return {
          name: "viewD",
        };
      } else if (to.query.c == "show" && to.query.id == "22") {
        return {
          name: "viewE",
        };
      } else if (to.query.c == "show" && to.query.id == "21") {
        return {
          name: "viewF",
        };
      } else if (to.query.c == "show" && to.query.id == "23") {
        return {
          name: "viewG",
        };
      } else if (to.query.c == "show" && productList.includes(to.query.id)) {
        //跳转到产品详情页面
        return {
          name: "detail",
        };
      } else if (
        to.query.c == "category" &&
        productCategoryList.includes(to.query.id)
      ) {
        return {
          name: "category",
        };
      } else if (to.query.c == "category" && to.query.isFeature) {
        return {
          name: "category",
        };
      } else if (to.query.c == "category" && to.query.id == "25") {
        return {
          name: "about",
        };
      } else if (to.query.c == "category" && to.query.id == "26") {
        return {
          name: "companyNews",
        };
      } else if (to.query.c == "category" && to.query.id == "35") {
        return {
          name: "join",
        };
      } else if (to.query.c == "category" && to.query.id == "6") {
        return {
          name: "contact",
        };
      } else if (to.query.keyword | (to.query.c == "search")) {
        return {
          name: "search",
        };
      }
    },
    component: () => import("@/views/pc/ProductCenter.vue"),
  },
  {
    path: "/index.php",
    name: "indexforphp",
    component: () => import("@/views/pc/IndexView.vue"),
  },
  {
    path: "/index.php",
    name: "service",
    component: () => import("@/views/pc/TechnicalService.vue"),
  },
  {
    path: "/index.php",
    name: "product",
    component: () => import("@/views/pc/ProductCenter.vue"),
  },
  {
    path: "/index.php",
    name: "about",
    component: () => import("@/views/pc/AboutView.vue"),
  },
  {
    path: "/index.php",
    name: "contact",
    component: () => import("@/views/pc/ContactView.vue"),
  },
  {
    path: "/index.php",
    name: "category",
    component: () => import("@/views/pc/ProductCategory.vue"),
  },
  {
    path: "/index.php",
    name: "detail",
    component: () => import("@/views/pc/ProductDetail.vue"),
  },
  {
    path: "/index.php",
    name: "companyNews",
    component: () => import("@/views/pc/CompanyNews.vue"),
  },
  {
    path: "/index.php",
    name: "join",
    component: () => import("@/views/pc/JoinUs.vue"),
  },
  {
    path: "/index.php",
    name: "viewA",
    component: () => import("@/views/pc/TechnicalServiceView/ViewA.vue"),
  },
  {
    path: "/index.php",
    name: "viewB",
    component: () => import("@/views/pc/TechnicalServiceView/ViewB.vue"),
  },
  {
    path: "/index.php",
    name: "viewC",
    component: () => import("@/views/pc/TechnicalServiceView/ViewC.vue"),
  },
  {
    path: "/index.php",
    name: "viewD",
    component: () => import("@/views/pc/TechnicalServiceView/ViewD.vue"),
  },
  {
    path: "/index.php",
    name: "viewE",
    component: () => import("@/views/pc/TechnicalServiceView/ViewE.vue"),
  },
  {
    path: "/index.php",
    name: "viewF",
    component: () => import("@/views/pc/TechnicalServiceView/ViewF.vue"),
  },
  {
    path: "/index.php",
    name: "viewG",
    component: () => import("@/views/pc/TechnicalServiceView/ViewG.vue"),
  },
  {
    path: "/article",
    name: "article",
    component: () => import("@/views/pc/ArticleDetail.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/pc/SearchView.vue"),
  },
  {
    path: "/literature",
    name: "literature",
    component: () => import("@/views/pc/LiteratureView.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () => import("@/views/pc/UpdatePassword.vue"),
  },
  {
    path: "/orderConfirm",
    name: "orderConfirm",
    component: () => import("@/views/pc/CheckOrder.vue"),
  },
  {
    path: "/payOrder",
    name: "payOrder",
    component: () => import("@/views/pc/PayOrder.vue"),
  },
  {
    path: "/ofPublic",
    name: "ofPublic",
    component: () => import("@/views/pc/ofPublic.vue"),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/pc/PersonalCenter/OrderDetail.vue"),
  },
  {
    path: "/afterSaleDetail",
    name: "afterSaleDetail",
    component: () => import("@/views/pc/PersonalCenter/AfterSaleDetail.vue"),
  },
  {
    path: "/integralAfterSaleDetail",
    name: "integralAfterSaleDetail",
    component: () =>
      import("@/views/pc/integralPage/integralAfterSaleDetail.vue"),
  },
  {
    path: "/integral",
    name: "Integral",
    component: () => import("@/views/pc/integralPage/integralMall.vue"),
  },
  {
    path: "/integral/detail",
    name: "IntegralDetail",
    component: () => import("@/views/pc/integralPage/integralDetail.vue"),
  },
  {
    path: "/intergral/checkOrder",
    name: "intergralCheckOrder",
    component: () => import("@/views/pc/integralPage/intergralCheckOrder.vue"),
  },

  {
    path: "/intergral/pay",
    name: "intergralPay",
    component: () => import("@/views/pc/integralPage/integralPay.vue"),
  },
  {
    path: "/TestPage",
    name: "TestPage",
    component: () => import("@/views/pc/TestPage.vue"),
  },
  {
    path: "/intergral/order/detail",
    name: "integralOrderDetail",
    component: () => import("@/views/pc/integralPage/integralOrderDetail.vue"),
  },
  {
    path: "/mine",
    redirect: "/mine/vip",
    name: "mine",
    component: () => import("@/views/pc/PersonalCenter/PersonalCenter.vue"),
    children: [
      {
        path: "cart",
        name: "cart",
        component: () => import("@/views/pc/PersonalCenter/CartView.vue"),
      },
      {
        path: "order",
        name: "order",
        component: () => import("@/views/pc/PersonalCenter/MyOrder.vue"),
      },
      {
        path: "address",
        name: "address",
        component: () =>
          import("@/views/pc/PersonalCenter/DeliveryAddress.vue"),
      },
      {
        path: "download",
        name: "download",
        component: () => import("@/views/pc/PersonalCenter/MyDownload.vue"),
      },
      {
        path: "vip",
        name: "vip",
        component: () => import("@/views/pc/PersonalCenter/VipCenter.vue"),
      },
      {
        path: "integralDetail",
        name: "integralDetail",
        component: () =>
          import("@/views/pc/PersonalCenter/IntegralDetailList.vue"),
      },
      {
        path: "invoiceInformation",
        name: "InvoiceInformation",
        component: () =>
          import("@/views/pc/PersonalCenter/InvoiceInformation.vue"),
      },
    ],
  },
];
const routes_mobile = [
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: "/404",
  },
  {
    path: "/",
    redirect: "/mobile",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/AnyView.vue"),
  },
  {
    path: "/mobile",
    name: "mobile",
    component: () => import("@/views/mobile/IndexView.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "transfer",
    meta: { footerHidde: true, headHidde: true },
    component: () => import("@/views/mobile/TransferView.vue"),
  },
  {
    path: "/mobile/login",
    name: "login",
    component: () => import("@/views/mobile/LoginView.vue"),
    meta: { footerHidde: true, headHidde: true },
  },
  {
    path: "/mobile/index.php",
    name: "contact",
    component: () => import("@/views/mobile/ContactView.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "about",
    component: () => import("@/views/mobile/AboutView.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "news",
    component: () => import("@/views/mobile/CompanyNews.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "join",
    component: () => import("@/views/mobile/JoinUs.vue"),
  },
  {
    path: "/mobile/jobDetails",
    name: "jobDetails",
    component: () => import("@/views/mobile/JobDetails.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "service",
    component: () => import("@/views/mobile/TechnicalService.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "services",
    component: () => import("@/views/mobile/TechnicalServiceIndex.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "viewA",
    component: () => import("@/views/mobile/TechnicalServiceView/ViewA.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "viewB",
    component: () => import("@/views/mobile/TechnicalServiceView/ViewB.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "viewC",
    component: () => import("@/views/mobile/TechnicalServiceView/ViewC.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "viewD",
    component: () => import("@/views/mobile/TechnicalServiceView/ViewD.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "viewE",
    component: () => import("@/views/mobile/TechnicalServiceView/ViewE.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "product",
    component: () => import("@/views/mobile/ProductCenter.vue"),
  },
  {
    path: "/mobile/index.php",
    name: "detail",
    component: () => import("@/views/mobile/ProductDetail.vue"),
  },
  {
    path: "/mobile/cart",
    name: "cart",
    component: () => import("@/views/mobile/PersonalCenter/CartView.vue"),
    meta: { footerHidde: true },
  },
  {
    path: "/mobile/address",
    name: "address",
    component: () =>
      import("@/views/mobile/PersonalCenter/DeliveryAddress.vue"),
    meta: { footerHidde: true },
  },
  {
    path: "/mobile/edit",
    name: "edit",
    component: () =>
      import("@/views/mobile/PersonalCenter/AddOrUpdateAddress.vue"),
    meta: { footerHidde: true, headHidde: true },
  },
  {
    path: "/mobile/order",
    name: "order",
    component: () => import("@/views/mobile/PersonalCenter/MyOrder.vue"),
    meta: { footerHidde: true },
  },
  {
    path: "/mobile/order/:id",
    name: "orderDetail",
    component: () => import("@/views/mobile/PersonalCenter/OrderDetail.vue"),
    meta: { footerHidde: true },
  },
  {
    path: "/mobile/download",
    name: "download",
    component: () => import("@/views/mobile/PersonalCenter/MyDownload.vue"),
    meta: { footerHidde: true },
  },
  {
    path: "/mobile/pdf",
    name: "pdf",
    component: () => import("@/views/mobile/PersonalCenter/PdfView.vue"),
    meta: { footerHidde: true, headHidde: true },
  },
  {
    path: "/mobile/search",
    name: "search",
    component: () => import("@/views/mobile/SearchView.vue"),
    meta: { footerHidde: true, headHidde: true },
  },
  {
    path: "/mobile/intergral",
    name: "intergral",
    component: () => import("@/views/mobile/integralPage/integralMall.vue"),
  },
  {
    path: "/mobile/intergral/detail",
    name: "intergralDetail",
    component: () =>
      import("@/views/mobile/integralPage/IntegralMallDetail.vue"),
  },
];

let toDownRoute = [
  "detail",
  "service",
  "companyNews",
  "join",
  "contact",
  "Integral",
  "IntegralDetail",
  "article",
];

const router = createRouter({
  history: createWebHistory(),
  routes: isMobileFlag ? routes_mobile : routes_pc,
  scrollBehavior(e) {
    if (toDownRoute.indexOf(e.name) >= 0) {
      return {
        top: 700,
      };
    } else {
      return {
        top: 0,
      };
    }
  },
});

export default router;
